import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import FooterTop from './footerTop';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function Footer() {

    let navigate = useNavigate();

    return (

        <footer className="pt-5 mt-auto footer">
            <FooterTop />
            <div className="container-fluid footer-bottom">
                <div className="container">
                    <div className="row align-items-center d-flex justify-content-center">

                        <Col className="mb-lg-4 mt-lg-4 mt-5 align-items-center" lg={3} sm={12} xs={12}>
                            <div className='d-flex justify-content-start'>

                                <div className='d-flex justify-content-start align-items-center'>
                                    <Image width="100" height="100" alt="" src="/logo.webp" />
                                    <div className="text-left footer-text-logo">
                                        Запорізька<br />
                                        <span className='nowrap'>міська рада</span>
                                    </div>

                                </div>

                            </div>

                        </Col>

                        <Col className="mb-lg-4 mt-lg-3 mt-3 align-items-center footer-fontsize px-4" lg={6} sm={12} xs={12}>
                            <div className="py-3">
                                Передрук та інше використання матеріалів, що розміщені на сайті дозволяється за умови прямого посилання на сторінку сайта
                            </div>
                            <div className="">
                            © 2024 КОМУНАЛЬНЕ ПІДПРИЄМСТВО "ЦЕНТР УПРАВЛІННЯ ІНФОРМАЦІЙНИМИ ТЕХНОЛОГІЯМИ". Всі права захищені.
                            </div>

                        </Col>

                        <Col className="mb-lg-4 mt-lg-3 mb-4 mt-4 align-items-center footer-fontsize px-4 px-lg-4 " lg={3} sm={12} xs={12}>
                            <div className="">
                                Технічне забезпечення<br />КП
                                "Центр управління інформаційними технологіями"
                            </div>
                            <div className="move-to-tech-support" onClick={() => { navigate('/techsupport') }}>
                                Технічна підтримка
                            </div>
                        </Col>
                        
                            
                        
                    </div>
                </div>

            </div>

        </footer>


    );
}

export default Footer;