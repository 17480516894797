import Container from 'react-bootstrap/Container';
import Page from './components/page';
import React, { useEffect } from 'react';
import Pagetitle from './components/Pagetitle';


function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Політика конфіденційності';
    }, []);
    return (
        <>
            <Container>
                <Page />
                <Pagetitle title={'Політика конфіденційності'} />
                <p>
                    <h4>Загальні положення</h4>
                    <p>Політика конфіденційності (далі - Політика) встановлює принципи щодо дотримання конфіденційності і захисту 
                    персональних даних, а також загальний порядок і умови роботи. В процесі обробки персональних даних Контакт-Центр 
                    керується Законом України «Про захист персональних даних» № 2297- VI від 01.06.2010 (далі - Закон), іншими 
                    нормативно - правовими актами України, внутрішніми документами, що регламентують діяльність. Користування ресурсу, 
                    встановленими на ньому сервісами означає, що Користувач згоден з цією політикою збереження конфіденційності і приймає 
                    умови, зазначені в Політиці. У разі незгоди з умовами Політики Користувач повинен припинити користуватися 
                    Сайтом і утриматися від використання сервісів. Дана Політика застосовується тільки до сайтів і ресурсів. 
                    Ресурс може містити посилання на інші сервіси і сайти Інтернету, якими не керує Контакт-Центр. 
                    Такі посилання наведені виключно для інформаційних цілей. Контакт-Центр не несе відповідальність за сайти, сервіси третіх
                    осіб, на які Користувач може перейти по посиланнях, доступним на Веб-сайтах, якими не керує Контакт-Центр.</p>
                </p>
                <p>
                    <h4>Отримання персональних даних</h4>
                    <p>Контакт-Центр отримує персональні дані на підставі добровільного та усвідомленого рішення суб'єкта персональних даних
                    щодо передачі даних. Обробці підлягають тільки ті персональні дані і тільки в тому обсязі, які відповідають цілям їх обробки.
                    Персональні дані обробляються і зберігаються відповідно до норм законодавства України. При роботі на Ресурсі Контакт-Центр, 
                    Користувачеві пропонується ввести деякі персональні дані (ім'я, адреса електронної пошти, номер контактного телефону). 
                    Контакт-Центр може запитати додаткові дані, які знадобляться для надання послуг Користувачу (для реалізації зобов'язань). 
                    Користувач зобов'язується надати достовірну інформацію і несе персональну відповідальність за достовірність інформації. 
                    Адміністрація Контакт-Центру не перевіряє достовірність персональних даних, наданих Користувачем. У разі відмови від 
                    надання всієї необхідної інформації для реалізації прийнятих зобов'язань, Контакт-Центр залишає за собою право відмовити в 
                    наданні послуг або призупинити їх дію.</p>
                </p>
                <p>
                    <h4>Використання персональних даних</h4>
                    <p>Персональні дані не підлягають розголошенню і не передаються третій стороні, якщо це не вимагає виконання прийнятих 
                    зобов'язань по реалізації послуг або якщо це не суперечить чинному законодавству України. Отримані персональні дані 
                    використовуються тільки для надання замовлених послуг (реалізації зобов'язань). Якість і швидкість надання послуг 
                    безпосередньо залежить від достовірності наданої інформації. Деякі персональні дані можуть використовуватися для письмових 
                    повідомлень (інформування) Користувача на замовлення, про нові пропозиції, а також з метою опитувань, маркетингових, 
                    статистичних та інших досліджень. Користувач в будь-який момент може відмовитися (відписатися) від інформування.</p>
                </p>
                <p>
                    <h4>Згода на обробку персональних даних</h4>
                    <p>Користувач, передаючи свої персональні дані, - погоджується з умовами Політики конфіденційності. Всі особи, які надали 
                    персональні дані через Сайт або іншим способом дають свою згоду на обробку персональних даних, на їх передачу 
                    розпоряднику персональних даних та / або третій стороні, які беруть участь в реалізації замовлених послуг або продуктів.
                    Згода на обробку персональних даних поширюється як на передану інформацію за допомогою заповнення форм на Сайті, так і на 
                    інформацію, передану в Контакт-Центр на друкованих носіях (копії документів). Згода на обробку персональних даних може бути 
                    відкликана шляхом письмового повідомлення.</p>
                </p>
                <p>
                    <h4>Діти</h4>
                    <p>Контакт-Центр цілком поділяє настороженість батьків в питаннях несанкціонованих дій дітей при їх відвідуванні 
                    будь-яких Інтернет-ресурсів. Контакт-Центр навмисно не збирає персональні дані від дітей у віці до 18 років. Настійно просимо 
                    всіх користувачів молодше 18 років отримувати дозвіл батьків або опікунів, перш ніж надавати будь-яку персональну інформацію.
                    Якщо кому-небудь стало відомо, що фізична особа у віці до 18 років передало свої персональні дані без дозволу батьків, - негайно 
                    повідомте нам про це. Ми вживемо заходів для видалення такої інформації в можливо короткі терміни.</p>
                </p>
                <p>
                    <h4>Захист персональних даних</h4>
                    <p>Контакт-Центр приймає розумні організаційні заходи для захисту персональних даних Користувача від неправомірного або 
                    випадкового доступу, знищення, перекручення, поширення, а також від інших неправомірних дій з боку третіх осіб. У той же час, 
                    Контакт-Центр не може гарантувати абсолютного усунення ризику несанкціонованого зловживання персональною інформацією. 
                    Негайно зв'яжіться з нами, якщо стане відомо про будь-яке порушення інформаційної безпеки.</p>
                </p>
                <p>
                    <h4>Видалення персональних даних</h4>
                    <p>Відповідно до чинного законодавства України, в системі реалізовано право    Користувача на видалення особистих даних .
Користувач в будь-який момент має право видалити свій обліковий запис  із Системи   та свої облікові дані та відомості (у тому числі дані, які було надано користувачем під час реєстрації у Системі.
Робота деяких можливостей Сайту, для яких необхідна наявність інформації про   Користувача, може бути припинена з моменту видалення даних.
Достатнім повідомленням Користувача про видалення облікових даних буде повідомлення про видалення облікового запису, яке з'явиться за допомогою діалогового вікна.
</p>
                </p>
                <p>
                    <h4>Заключні положення</h4>
                    <p>На розсуд Контакт-Центр в положення даної Політики можуть вноситися зміни (редагування, доповнення) без попереднього 
                        повідомлення. Нові положення Політики конфіденційності вступають в силу з моменту їх публікації на Сайті, якщо 
                        інше не передбачено новою редакцією Політики. Всі пропозиції, побажання та питання з приводу Політики слід надсилати 
                        за вказаними на Сайті контактними даними.</p>
                </p>
            </Container>
        </>
    );
}

export default PrivacyPolicy;

