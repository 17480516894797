import Container from 'react-bootstrap/Container';
import Page from '../page';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import { GoSearch } from "react-icons/go";
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
//import data from '../mainpage/data1.json'
//import RepairWorks from '../../services/RepairWorks';
import React, { useState, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
//import { TailSpin } from 'react-loader-spinner'
import { ThreeDots } from 'react-loader-spinner'
import { IoMdAlert } from "react-icons/io";
//import { RiAlarmWarningFill } from "react-icons/ri";
import ShowMoreText from "react-show-more-text";
import InfiniteScroll from "react-infinite-scroll-component";
import Pagetitle from '../Pagetitle';
import { IoReloadCircleSharp, IoCloseCircleSharp } from "react-icons/io5";


function Remontworks() {


    const animatedComponents = makeAnimated();
    const [selectedValue, setSelectedValue] = useState(null);
    const [showsblank, setshowsblank] = useState(false);
    const [showspiner, setShowspiner] = useState(false);
    const [itemOffset, setItemOffset] = useState(0);
    const [news, setNews] = useState([]);
    const [hasMore, sethasMore] = useState(false);
    const [needupdate, setNeedupdate] = useState(false);

    const fetchMoreData = () => {

        if (!hasMore) {
            setShowspiner(true)
        }

        let label = null;
        let value = null;

        if (selectedValue != null) {
            label = selectedValue.label;
            value = selectedValue.value;
        }

        axios.post("/api/repairworks/list", {
            label: label,
            value: value,
            page: itemOffset
        }

        ).then((response) => {
            setshowsblank(response.data.empty)
            if (itemOffset === 0) {
                setNews([])
            }
            setNews((news) => [...news, ...response.data.content]);
            setItemOffset(response.data.number + 1)
            sethasMore(!response.data.last)

            setShowspiner(false)
        }).catch(error => {
            setShowspiner(false)
        })
    }

    const handleChange = value => {
        setNews([])
        setShowspiner(false)
        setItemOffset(0)
        sethasMore(true)
        setshowsblank(false)
        setSelectedValue(value);
    }

    useEffect(() => {
        fetchMoreData()
    }, [selectedValue, needupdate]);


    const update = () => {
        setItemOffset(0)
        setshowsblank(false)
        sethasMore(true)
        setNews([])
        setNeedupdate(!needupdate)
    }


    useEffect(() => {
        document.title = 'Ремонтні роботи – Контакт–Центр м. Запоріжжя 1580';
        window.scrollTo(0, 0);
    }, []);


    const loadOptions = async (str) => {
        if (str.length >= 3) {
            const response = await axios.post("/api/repairworks/streetlist",
                { str: str });
            return response.data;
        }
    };


    // const DropdownIndicator = props => {
    //     return (
    //         components.DropdownIndicator && (
    //             <components.DropdownIndicator {...props}>
    //                 <span className='search-icon'><GoSearch /></span>
    //             </components.DropdownIndicator>
    //         )
    //     );
    // };

    const ClearIndicator = props => {
        return (
          <components.ClearIndicator {...props}>
           <IoCloseCircleSharp className='cp' size={30} color='#a1d2b8' />
          </components.ClearIndicator>
        );
      };

    return (
        <>
            <ScrollToTop smooth color="#028B42" className='m2'
            />
            <Container>
                <Page />

                <Pagetitle title={'Ремонтні роботи'} />

                <Row className='mb-4 mt-4'>
                <div class="col-10">
                        <AsyncSelect classNamePrefix="mySelect"

                            defaultOptions
                            cacheOptions

                            // components={{ DropdownIndicator, animatedComponents }}
                            components={{ DropdownIndicator: null, ClearIndicator, animatedComponents}}
                            loadOptions={(str) => loadOptions(str)}

                            onChange={(value) => handleChange(value)}
                            value={selectedValue}
                            closeMenuOnSelect
                            loadingMessage={() => 'Шукаю, зачекайте...'}

                            placeholder={'Пошук за адресою'}
                            noOptionsMessage={() => 'Введіть мініум 3 літери або уточніть пошук'}
                            isClearable

                            getOptionLabel={(option) => `${option.labeltype} ${option.label}`}

                            styles={{
                                option: (styles, state) => ({ // fixed
                                    ...styles,
                                    fontSize: state.isSelected ? 21 : 19,
                                    color: state.isSelected ? 'black' : '#444444'
                                }),
                            }}

                            theme={(theme) => ({
                                ...theme,

                                colors: {
                                    ...theme.colors,
                                    primary25: '#E1E4E8',
                                    primary: '#c2e6d3',
                                },
                            })}

                        />


                    </div>
                    <div class="col-2"><IoReloadCircleSharp onClick={()=> update()} className='cp' size={47} color='#a1d2b8' /> </div>
                </Row>





                <div>
                    <InfiniteScroll
                        dataLength={itemOffset}
                        next={fetchMoreData}
                        hasMore={hasMore}
                        loader={
                            <div className='d-grid d-flex justify-content-center'>
                                <ThreeDots
                                    height="130"
                                    width="130"
                                    radius="9"
                                    color="#4fa94d"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>}

                    // endMessage=
                    // {!showsblank &&
                    //     <p style={{ textAlign: 'center' }}>
                    //         <b>Кінець</b>
                    //     </p>
                    // }

                    >


                        {news && news.map((d, index) => (
                            <div key={index}>
                                <div className='row'>
                                    <Col lg={10} className="p-1">
                                        <Card className=' shadow-sm bg-body rounded repeir-card' >

                                            <div class="card-header">
                                                <div class="col d-flex align-items-center">
                                                    <div class="remontworks-icon me-3" style={{ backgroundImage: `url( ${'/repeirworks/' + d.problem_id + '.png'} )` }}>
                                                        {d.is_emergency != null &&
                                                            <div className='repeirs-alarm'><IoMdAlert /></div>
                                                        }
                                                    </div>

                                                    <div className='me-auto pt-2'>
                                                        <h5 className='fw-bold'>{d.problem}</h5>
                                                        <h6 class="mt-2 m2"> {d.reason}</h6>
                                                    </div>

                                                </div>
                                                <div class="col">
                                                    <h6 class="m1 py-1">{d.reason}</h6>
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <div className='col border-bottom repeir-date-m m1'>
                                                    <div class="nowrap" style={{fontSize: '15px'}}>Дата початку: {d.date_beg}</div>
                                                    <div class="nowrap" style={{fontSize: '15px'}}>Планова дата закінчення: {d.date_plan}</div>
                                                </div>
                                                {/* <div class="mt-1 m1">{d.reason}</div> */}

                                                <div class="col d-flex align-items-start me-auto">

                                                    <div>
                                                        {d.addresses.split(';').map(ad => <div className='py-1'>{ad}</div>)}
                                                    </div>
                                                </div>


                                            </Card.Body>

                                            <div class="card-footer text-muted">
                                                <div class="mt-2">

                                                    <ShowMoreText
                                                        lines={1}
                                                        more=">"
                                                        less="<"
                                                        //  className="nowrap"
                                                        // anchorClass="show-more-less-clickable"

                                                        expanded={false}
                                                        width={300}
                                                        truncatedEndingComponent={"... "}
                                                    >
                                                        Примітка: {d.event_text}
                                                    </ShowMoreText>
                                                </div>

                                                <div class="mt-2 col">Виконавець: {d.executor}</div>


                                            </div>
                                        </Card>
                                    </Col>

                                    <Col lg={2} className="m2 p-1">
                                        <Card className='h-100 shadow-sm bg-body rounded repeir-card'>
                                            <Card.Body>

                                                <Card.Text>

                                                    <div class="card-img-overlay d-flex flex-column" style={{fontSize: '16px'}}>
                                                        <div><span class="fw-bold">Дата початку:</span>
                                                            <div className="nowrap">{d.date_beg}</div>
                                                           {/* <div>{d.date_beg.split(' ')[1]}</div>*/}
                                                        </div>

                                                        <div class="mt-auto"><span class="fw-bold" style={{fontSize: '16px'}}>Планова дата закінчення:</span>
                                                            <div className="nowrap">{d.date_plan}</div>
                                                           {/* <div>{d.date_plan.split(' ')[1]}</div>*/}


                                                        </div>
                                                    </div>

                                                </Card.Text>



                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </div>
                            </div>
                        ))}

                    </InfiniteScroll>
                </div>

                {!hasMore && showspiner &&
                    <div className='d-grid d-flex justify-content-center p-5'>
                        <ThreeDots
                            height="130"
                            width="130"
                            radius="9"
                            color="#4fa94d"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                }
                {showsblank &&
                    <>
                        <div class="notfound py-5">

                            <section class="text-center"><span className='rep2-icon'><IoMdAlert /></span></section>
                            <section class="text-center py-1"><span className='fw-normal'>Ремонтних робіт не знайдено</span></section>

                        </div>
                    </>
                }

            </Container>

        </>

    );
}

export default Remontworks;