import Container from 'react-bootstrap/Container';
import Page from '../page';
import Pagetitle from '../Pagetitle';
import Col from 'react-bootstrap/Col';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import React, { useState, useEffect, useRef } from "react"
import Select, { components } from "react-select";
import axios from "axios";
import Row from 'react-bootstrap/Row';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { defaultMarker } from "./defaultMarker"
//import { popup } from "./popup"
import mapService from '../../services/mapService';
import { MdOutlineArrowDropDownCircle } from "react-icons/md";
import { useElementSize } from 'usehooks-ts'
import CountUp from 'react-countup';
import { LanimateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import PopupModal from './popupModal';
import { ThreeDots } from 'react-loader-spinner'
import { IoCloseCircleSharp } from "react-icons/io5";


function Requests() {


    const [curentProblem, setCurentProblem] = useState(null);
    const [showmodal, setShowmodal] = useState(false);
    const [showSpinerMap, setShowSpinerMap] = useState(false);
    //const [d, setD] = useState();
    const d = useRef(null);
    const [data, setData] = useState([]);
    const [groupe_marker, setGroupe_marker] = useState(true);

    const [mapContent, setMapContent] = useState([]);

    const [count_v1, setCount_v1] = useState();
    const [count_v2, setCount_v2] = useState();

    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = state;

    const handleCallback = (start, end) => {
        setState({ start, end });

        console.log(state.start._d);
        console.log(state.end._d);


        scroller.scrollTo('reportrange', {
            duration: 300,
            delay: 0,
            smooth: true,
            offset: 0
        })
    };
    const labels =
        start.format('DD-MM-YYYY');

    const labele =
        end.format('DD-MM-YYYY');


    useEffect(() => {
        document.title = 'Звернення мешканців – Контакт–Центр м. Запоріжжя 1580';
        scroller.scrollTo('root', {
            duration: 0,
            delay: 0,
            smooth: false,
            offset: 0
        });

        axios.post("/api/general/getProblem_main", {}
        ).then((response) => {
            setData(response.data)
        }).catch(error => {
        });

    }, []);

    useEffect(() => {

        let problem = '0';

        if (curentProblem != null) {
            problem = curentProblem.id;
        }


        setShowSpinerMap(true)
        mapService.getMap(problem, state.start._d, state.end._d).then((response) => {
            setMapContent(response.data)

            let count1 = 0;
            let count2 = 0;

            response.data.forEach(function (item, i) {
                if (item.status_id === 1 || item.status_id === 6) {
                    count1++
                } else {
                    count2++
                }

            });

            setCount_v1(count1)
            setCount_v2(count2)
            setShowSpinerMap(false)
        }).catch(error => {
            setShowSpinerMap(false)
        })

    }, [curentProblem, state]);

    const outerBounds = [
        [47.65, 34.8],
        [47.99, 35.4]
    ]


    const IconOption = props => (
        <Option {...props}>
            <div className='request-on_wrap'>
            <img style={{marginRight: '7px'}} src= {'/' + props.data.id + '.webp'} height={45} width={45} class="img-fluid" alt={'problem' + props.data.id}></img>
                <div className='title_m_r'>
                    {props.data.name}
                </div>
            </div>

        </Option>
    );
    const { Option } = components;
    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            <img src={`${'/' + props.data.id + '.webp'}`} alt="select_problem" className="icon-request-on-select" />
            {children}
        </components.SingleValue>
    );

    const [mapsize, { width, height }] = useElementSize()

    const handleProblem = (value) => {
        if (value === null) {
            setCurentProblem(null);
        } else {
            setCurentProblem(value);
        }
    }
    // const createClusterCustomIcon = function (cluster) {
    //     return L.divIcon({
    //         html: `<span>${cluster.getChildCount()}</span>`,
    //         className: "customMarker",
    //         iconSize: L.point(40, 40, true)
    //     });
    // };

    const handleShowReqClick = (id) => {
        d.current = id
        setShowmodal(true)
        // console.log(d.current)
    }

    const Markers = () => {
        return <>

            {mapContent.map(d => (<>
                {d.status_id === 1 || d.status_id === 6 ? (
                    <>
                        <Marker
                            eventHandlers={{
                                click: () => {
                                    handleShowReqClick(d.id)
                                },
                            }}
                            icon={defaultMarker(d.problem_id, 2)}
                            key={d.id}
                            position={[d.geo_lat, d.geo_lon]}>

                        </Marker>
                    </>
                ) : (
                    <>
                        <Marker
                            eventHandlers={{
                                click: () => {
                                    handleShowReqClick(d.id)
                                },
                            }}
                            icon={defaultMarker(d.problem_id, 1)}
                            key={d.id}
                            position={[d.geo_lat, d.geo_lon]}>

                        </Marker>
                    </>
                )}
            </>
            ))}
        </>
    }

    const ClearIndicator = props => {
        return (
          <components.ClearIndicator {...props}>
           <IoCloseCircleSharp className='cp' size={30} color='#a1d2b8' />
          </components.ClearIndicator>
        );
      };
    return (
        <>
            <PopupModal show={showmodal} handleClose={() => setShowmodal(false)} data={d.current} />
            <Container>
                <Page />
                <Pagetitle title={'Звернення мешканців'} />

                <Row>
                    <Col sm={12} xl={4} className="pb-xl-0 pt-xl-0 pt-4 pb-2 order-sm-2 order-xl-1 order-2">

                        <div className='d-flex justify-content-center d-flex justify-content-sm-start py-3 py-sm-1'>
                            <DateRangePicker

                                initialSettings={{
                                    locale: {
                                        format: "DD-MM-YYYY",
                                        cancelLabel: 'Відміна',
                                        applyLabel: 'Встановити',
                                        firstDay: 1,
                                        customRangeLabel: 'Вибрати період',
                                        separator: ' - ',
                                        daysOfWeek: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                                        monthNames: ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"]
                                    },
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                    ranges: {
                                        "Сьогодні": [moment().toDate(), moment().toDate()],
                                        // "Вчора": [
                                        //     moment().subtract(1, 'days').toDate(),
                                        //     moment().subtract(1, 'days').toDate(),
                                        // ],
                                        'Останні 7 днів': [
                                            moment().subtract(6, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Останні 30 днів': [
                                            moment().subtract(29, 'days').toDate(),
                                            moment().toDate(),
                                        ],
                                        'Цей місяц': [
                                            moment().startOf('month').toDate(),
                                            moment().endOf('month').toDate(),
                                        ],
                                        // 'Last Month': [
                                        //     moment().subtract(1, 'month').startOf('month').toDate(),
                                        //     moment().subtract(1, 'month').endOf('month').toDate(),
                                        // ],
                                    },
                                }}
                                onCallback={handleCallback}
                            >
                                <div className='d-flex justify-content-center shadow-sm'
                                    id="reportrange"

                                    style={{
                                        background: '#a1d2b8',
                                        cursor: 'pointer',
                                        padding: '7px 10px',
                                        width: "330px",
                                        borderRadius: "7px",

                                    }}
                                >

                                    <span className='d-flex justify-content-center'
                                        style={{
                                            background: '#fff',
                                            padding: '5px 9px',
                                            width: "100%",
                                            borderRadius: "4px",
                                        }}
                                    >{labels}</span>
                                    <span
                                        style={{
                                            color: '#fff',
                                            cursor: 'pointer',
                                            padding: '5px 10px',

                                            borderRadius: "10px",

                                        }}
                                    >&mdash;</span>

                                    <span className='d-flex justify-content-center'
                                        style={{
                                            background: '#fff',
                                            padding: '5px 9px',
                                            width: "100%",
                                            borderRadius: "4px",
                                        }}
                                    >{labele}</span>
                                </div>
                            </DateRangePicker>
                        </div>
                    </Col>


                    <Col sm={12} xl={8} className="pb-xl-0 pt-xl-0 pb-2  align-items-center order-sm-1 order-xl-2 order-1">
                        <Row className='nowrap'>
                            <Col>
                                <div className='w_rap'>
                                    <div class="wrap11_r wrap_border_yellow">
                                        {!showSpinerMap && <CountUp end={count_v1} separator="" decimal="" />
                                        }
                                        {/* <CountUp end={count_v1} /> */}
                                        {showSpinerMap &&
                                            <div className='d-flex justify-content-center'>
                                                <ThreeDots
                                                    height="40"
                                                    width="40"
                                                    radius="20"
                                                    color="#fff"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={showSpinerMap}
                                                />
                                            </div>
                                        }

                                    </div>
                                    <div className='d-inline-block nowrap requests-wrap_r_title pt-2'>
                                        У роботі
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className='w_rap'>
                                    <div class="wrap11_r wrap_border_green">
                                        {!showSpinerMap && <CountUp end={count_v2} separator="" decimal=""/>
                                        }
                                        {showSpinerMap &&
                                            <div className='d-flex justify-content-center'>
                                                <ThreeDots
                                                    height="40"
                                                    width="40"
                                                    radius="20"
                                                    color="#fff"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClassName=""
                                                    visible={showSpinerMap}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className='d-inline-block nowrap requests-wrap_r_title pt-2'>
                                        Опрацьовано
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* minZoom={12} */}
                <Col md={12} className="pb-2">

                    <Col md={12}>
                        <label for="mySelectrec" className="label">Оберіть проблему</label>
                        <Select
                            classNamePrefix="mySelectrec" className='shadow-sm'
                            components={{
                                Option: IconOption, SingleValue,
                                DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: ClearIndicator,
                            }}
                            isSearchable={false}
                            isClearable
                            // onChange={(value) => {
                            //     setHouse_p(value);
                            //     setmapCentr([47.8500, 35.1257]);
                            //     setmapZoom(13);
                            // }}

                            onChange={(value) => { handleProblem(value) }}
                            placeholder={<span><MdOutlineArrowDropDownCircle size={28} color={'#028B42'} />&nbsp;&nbsp;Усі проблеми</span>}
                            options={data}
                            getOptionValue={option => option.id}
                            value={curentProblem}
                            getOptionLabel={(option) => `${option.name}`}
                            defaultOptions
                            closeMenuOnSelect
                            maxMenuHeight={height}
                            menuShouldScrollIntoView
                            noOptionsMessage={() => ''}
                            styles={{
                                option: (styles, state) => ({ // fixed
                                    ...styles,
                                    fontSize: state.isSelected ? 21 : 19,
                                    color: state.isSelected ? 'black' : '#444444'
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#E1E4E8',
                                    primary: '#c2e6d3',
                                },
                            })}
                        />
                    </Col>

                </Col>

                <Col md={12} className="request-map shadow-sm" ref={mapsize}>
                    <div className='d-flex justify-content-center'>
                        <div className='spiner_map'>
                            <ThreeDots
                                height="430"
                                width="430"
                                radius="20"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={showSpinerMap}
                            />
                        </div>
                    </div>
                    <MapContainer
                        className="requests-map"
                        center={[47.8500, 35.1257]}
                        zoom={13}
                        // scrollWheelZoom={false}
                        zoomControl={false}
                        minZoom={9}
                        maxBounds={outerBounds}
                    >

                        <TileLayer
                            attribution='&copy; <a href="https://openstreetmap.org.ua/">OpenStreetMap</a>'
                            url="https://tiles.openstreetmap.org.ua/tile/{z}/{x}/{y}.png"
                        />

                        {groupe_marker ? (
                            <>
                                <MarkerClusterGroup>
                                    <Markers />
                                </MarkerClusterGroup>
                            </>
                        ) : (
                            <>
                                <Markers />
                            </>
                        )}
                        <div class="leaflet-bottom leaflet-left">
                            <div class="leaflet-control-attribution leaflet-control">
                                <div className="form-check groupe_marker">
                                    <input className="form-check-input" type="checkbox" name="markers"
                                        checked={groupe_marker} id="markers" onClick={() => setGroupe_marker(!groupe_marker)}></input>
                                    <label className="form-check-label cp" for="markers">
                                        Групувати зверення
                                    </label>
                                </div>
                            </div>

                        </div>
                    </MapContainer>

                </Col>

            </Container>

        </>

    );
}

export default Requests;