import axios from "axios";

const API_URL = "/api/auth"
const API_URL2 = "/api/user"

class AuthService {

     csrf = () => {
        return axios.post(API_URL + "/csrf");
    }

    checkUser = () => {
        return axios.get(API_URL2 + "/checkUser", {});
    }

    refreshtoken = (token) => {
        return axios.post(API_URL + "/refreshtoken", {
            token
        });
    }


    existEmail = (email) => {
        return axios.post(API_URL + "/existEmail", {
            email
        });
    }

    // existEmail2 = (email) => {
    //     return  axios.post(API_URL + "/existEmail2", {
    //         email
    //     });
    // }

    register = (username, email, password, name, surname, token) => {
        return axios.post(API_URL + "/signup", {
            username,
            email,
            password,
            name,
            surname,
            token
        });
    }

    //  login = (username, password, token) => {
    //     return axios.post(API_URL + "/signin", {
    //         username,
    //         password,
    //         token
    //     });
    // }

    changeuserpassword = (password, oldpassword) => {
        return axios.post(API_URL2 + "/changeuserpassword", {
            password,
            oldpassword
        });
    }

    resendEmailvertification = (token) => {
        return axios.post(API_URL + "/resendEmailvertification", { token }
        )
    }


    getCurrentUser = () => {
        return JSON.parse(localStorage.getItem("user"));
    };

    logout = () => {

        let curentuser = JSON.parse(localStorage.getItem("user"));

        let refreshToken = curentuser.refreshToken;

        localStorage.removeItem("user");

        if (curentuser != null) {
            axios.post(API_URL + "/logout", { refreshToken });
        }

    };

    profile = () => {
        return axios.get(API_URL2 + "/profilecard", {});
    }

    profile_card = () => {
        return axios.get(API_URL2 + "/profilecard_card", {});
    }

    
}

export default new AuthService();