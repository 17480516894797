import Container from 'react-bootstrap/Container';
import Page from '../page';
import React, { useState, useEffect } from 'react';
import Pagetitle from '../Pagetitle';
import NewsServive from '../../services/newsService';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { ThreeDots } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import { IoReloadOutline } from "react-icons/io5";


function News() {

  let navigate = useNavigate();

  const [showspiner, setShowspiner] = useState(false);
  const [last, setLast] = useState(true);
  const [news, setNews] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    document.title = 'Новини ЖКГ – Контакт–Центр м. Запоріжжя 1580';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {

    setShowspiner(true)
    NewsServive.getnews(pageCount).then((response) => {
      setNews((news) => [...news, ...response.data.content]);
      setLast(response.data.last)
      setShowspiner(false)
    }).catch(error => {
      setShowspiner(false)
      console.log(error)
    })

  }, [pageCount]);


  const handleNews = (id) => {

    navigate("/news/newsitem/" + id);
  }

  return (
    <>
      <Container>
        <Page />
        <Pagetitle title={'Новини ЖКГ'} />

        <Row className="d-flex justify-content-center">


          {news && news.map(d => (

            <Col md={6} sm={8} lg={6} xl={3} xxl={3} className='p-1 cursorp d-flex justify-content-center'>

              <Card id='news--card' key={d.id} className='shadow-sm bg-body rounded news-block' onClick={() => handleNews(d.id)}>

                <Card.Body className='news--body'>
                  

                  {d.header_IMG_ID !== null && <Image className='news-img'
                    alt={d.id} src={'/api/files/' + d.header_IMG_ID}
                  />}

                  <div className='p-3'>
                    {d.header_IMG_ID === null && <Image className='news-img'
                      alt={d.id} src={'/no-image-icon.png'}
                    />}
                    <div className="news__time">{d.create_DATE}</div>
                    <div className="news__title">
                      {d.title_UA}
                      <span className="news__info">
                        {d.description_UA}
                      </span>
                    </div>


                  </div>

                </Card.Body>

              </Card>

            </Col>

          ))}
        </Row>

        {showspiner &&
          <div className='d-grid d-flex justify-content-center p-5'>

            <ThreeDots
              height="140"
              width="140"
              radius="7"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />

          </div>
        }

        {!showspiner && !last &&
          <div className="text-center d-grid d-flex justify-content-center">
            <div className="p-4 more_news_hover" style={{ color: '#4fa94d', cursor: 'pointer' }} onClick={() => { setPageCount(pageCount + 1) }}>
              <div className='more_news'>
                <IoReloadOutline size={50} /></div>
              <div>Завантажити більше</div>
            </div>
          </div>
        }
      </Container>


    </>
  );
}

export default News;