import axios from "axios";

const TESTT_REST_API_NEWS = '/api/news/list';

class NewsServive {

    getnews(id) {
        return axios.get(TESTT_REST_API_NEWS + '/' + id);
    }

     getnewsitem(id) {
         return axios.get('/api/news/item/' + id);
     }

}

export default new NewsServive();