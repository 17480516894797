import React, { useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from 'react-bootstrap/Spinner';
//import AuthService from "../services/AuthService";
import { useForm } from "react-hook-form";
import { MdOutlineDone } from "react-icons/md";
import {
   
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import axios from "axios";
import Alert from 'react-bootstrap/Alert';




const RememberPasswordComponent = ({ show, onHide: closeModal, onExit, onShow }) => {

    const [showa, setShowa] = useState(false);

    const url = "/api/auth/resetpassword_request";

    const [spiner, setSpiner] = useState(false);
    const [showAlert, setShowAlert] = useState(false)
    const [showAlerttext, setShowAlerttext] = useState("Сталася помилка")
    const [errorr, setErrorr] = useState(false)
    const [emailOk, setemailOk] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(true);

    const {
        setError,
        register,
        formState: { errors },
        handleSubmit,
        reset
    } = useForm({
        mode: 'onChange',
        criteriaMode: 'firstError',
        shouldFocusError: true,
        shouldUnregister: true
    });

    onExit = () => {
        setSpiner(false)
        setShowAlert(false)
        setShowa(false)
        setemailOk(false)
        setErrorr(false)
        setShowCaptcha(false)
        reset()
    }

    onShow = () => {
        if (!showCaptcha){
            setShowCaptcha(true)
        }
    }
    /*  const isExist = () => {
 
         if (errors.email === undefined) {
 
             AuthService.existEmail2(getValues().email).then(
 
                 (response) => {
                     if (response.data.message === 'exist') {
                         setemailOk(false)
                     } else {
                         setError('email', { type: 'isExistEmail', message: 'Користувача з таким Email не знайдено' });
                         setemailOk(true)
                     }
                 },
                 (error) => {
                     setShowa(true)
                 }
             )
         }
     } */

    const RememberReCaptchaComponent = () => {

        const { executeRecaptcha } = useGoogleReCaptcha();

        const handleReCaptchaVerify = useCallback(async (data2) => {

            setSpiner(true)

            // if (emailOk) {
            //     setSpiner(false)
            //     setError('email', { type: 'isExistEmail', message: 'Користувача з таким Email не знайдено' });
            //     return
            // }

            if (!executeRecaptcha) {
                setSpiner(false)
                console.log('Execute recaptcha not yet available');
                return;
            }

            const token = await executeRecaptcha('rememberpassword');


            try {

                const response = await axios.post(url, { email: data2.email, token: token });

                if (response.status === 200) {

                    if (response.data.message === 'none') {
                        setSpiner(false)
                        setError('email', { type: 'isExistEmail', message: 'Користувача з таким Email не знайдено' });
                        return;
                    }

                    if (response.data.message === 'ok') {

                        setShowAlert(true)
                        setSpiner(false)

                    } else {

                        if (response.data.message === 'captha_error') {
                            setShowAlerttext("Помилка капчи. перезавантажте сторінку та спробуйте ще")
                        }

                        setErrorr(true)
                        //  setShowAlert(true)
                        setSpiner(false)
                    }


                } else {
                    if (response.status === 500) {
                        setShowa(true)
                        setSpiner(false)
                    }
                }
            } catch (err) {
                setShowa(true)
                setSpiner(false)

            }



        }, [executeRecaptcha]);

        return (
            <div className="py-4">

                <Button className="login_modal_button3 p-2 py-2" variant="primary" type="submit" disabled={spiner} onClick={handleSubmit(handleReCaptchaVerify)} >
                    {spiner ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        <>Відправити</>
                    )}
                </Button>{' '}

            </div>
        )
    }




    return (
        <>
            <Modal show={show} onHide={closeModal} onExit={onExit} fullscreen='md-down' onShow={onShow}
                aria-labelledby="rememberpassword"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Нагадати пароль </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>

                        {!showAlert ? (
                            <>
                                <Form onSubmit={handleSubmit}>

                                    <div className="py-2">
                                        Вкажіть email, який Ви використовували при реєстрації, на нього буде вислано посилання для скидання паролю
                                    </div>

                                    {showa ? <>
                                        <Alert variant='danger' onClose={() => setShowa(false)} dismissible>
                                            Сталася помилка, спробуйте повторити пізніше.
                                        </Alert>
                                    </>
                                        : <>
                                        </>}
                                    <Row>
                                        <Col>
                                            <label for="email" class="label">Email</label>
                                            <input
                                                {...register("email", {

                                                    required: "Обов’язкове поле!",

                                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                                                }
                                                )}

                                                type="text" class="form-control" id="email" aria-describedby="email"


                                            />

                                            {errors.email && errors.email.type === "pattern" && (
                                                <p className="labal-alert"><div>Невірний формат email</div></p>
                                            )}

                                            {errors.email && (
                                                <p className="labal-alert"><div>{errors.email.message}</div></p>
                                            )}
                                            {errors.isExistEmail && (
                                                <p className="labal-alert"><div>{errors.isExistEmail.message}</div></p>
                                            )}
                                        </Col>
                                    </Row>

                                    {errorr && <p className="labal-alert"><div>{showAlerttext}</div></p>
                                    }

                                    {showCaptcha && <>
                                        {/* <GoogleReCaptchaProvider reCaptchaKey="6LfiC1ghAAAAAIh6aNPHwnscnMWbBzNYc8FPW8n4" language="uk-UA" > */}

                                            {showAlert
                                                ? <></>
                                                :
                                                <RememberReCaptchaComponent />
                                            }

                                        {/* </GoogleReCaptchaProvider> */}
                                    </>}
                                </Form>
                            </>

                        ) : (
                            <>

                                <div className="d-flex justify-content-center row">
                                    <Row>
                                        <div className="col">
                                            <span className="rememberPaswordResult"><MdOutlineDone /></span>
                                        </div></Row><Row>
                                        <div className="col rememberPaswordResult2 py-2">
                                            Посилання для зміни паролю вислано на Ваш Email
                                        </div></Row>
                                </div>

                            </>
                        )}

                    </Container>
                    <Row>
                        <div className="captcha_p pt-5 col">
                            <p>Цей сайт захищено технологією reCAPTCHA, до нього застосовуються <a href="https://policies.google.com/privacy">Політика конфіденційності</a> та <a href="https://policies.google.com/terms">Умови використання</a> Google</p>
                        </div>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default RememberPasswordComponent