import Mainpagebaner from './mainpage/Mainpagebaner';
import Bloks from './mainpage/bloks';
import Whattoreport from './mainpage/whattoreport';
import Way from './mainpage/way';
import Howwork from './mainpage/howwork';
import React, { useEffect } from "react";
import alarmBloks from "./mainpage/alarmBloks";

function MainPage() {

    useEffect(() => {
        document.title = 'Контакт–Центр м. Запоріжжя 1580';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <alarmBloks.alarm1/>
            <Mainpagebaner/>

            <Bloks/>

            <Whattoreport/>

            <Way/>

            <Howwork/>
        </>

    );
}

export default MainPage;