import Container from "react-bootstrap/Container";
import Page from "../page";
import Pagetitle from "../Pagetitle";
import React, { useEffect } from "react";
import { FormattedText } from "./components";
import ProtalAbilities from "./abilities.json";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import alarmBloks from "..//mainpage/alarmBloks";
import { useNavigate } from 'react-router-dom';

function ProPortal() {
  useEffect(() => {
    document.title = 'Про портал – Контакт–Центр м. Запоріжжя 1580';
    window.scrollTo(0, 0);
  }, []);

  let navigate = useNavigate();

  return (
    <>
      <Container>
        <Page />
        <Pagetitle title={"Про портал"} />

      
          <div className="bd-callout bd-callout-info">
            <div class="col d-flex align-items-center">
              <span className="pro-portal">
                Портал «Контакт – Центр м. Запоріжжя 1580» створено Запорізькою міською радою для комунікації мешканців міста Запоріжжя з підприємствами і організаціями за різними напрямками, які задіяні у забезпеченні життєдіяльності міста.
              </span>
            </div>
          </div>
      

        <section className="p-2 pt-4">
          <span>За допомогою порталу Ви зможете:</span>
        </section>

        <div className="pro-portal-abilities-wrapper">
          {ProtalAbilities.map((item) => (
            <Card className="pro-portal-ability-item shadow-sm bg-body rounded" key={item.id}>
              <FormattedText text={item.text} />
            </Card>
          ))}
        </div>

       
          <Col sm={12} className="pro-portal2 p-3">
            
              У своїй роботі ми суворо дотримуємося норм Закону України{" "}
              <a
                href="https://zakon.rada.gov.ua/laws/show/2939-17#Text"
                target="_blank"
              >
                "Про доступ до публічної інформації"
              </a>
              , Закону України{" "}
              <a
                href="https://zakon.rada.gov.ua/laws/show/2297-17#Text"
                target="_blank"
              >
                "Про захист персональних даних"
              </a>
              , Закону України{" "}
              <a
                href="https://zakon.rada.gov.ua/laws/show/393/96-%D0%B2%D1%80#Text"
                target="_blank"
              >
                "Про звернення громадян"
              </a>
              , та{" "}
              <span className="politic-conf" onClick={() => navigate("/PrivacyPolicy")}>
                Політики конфіденційності
              </span>
         
          </Col>
          <alarmBloks.alarm3/>
      </Container >
    </>
  );
}

export default ProPortal;
