
import axios from "axios";

class MapServive {

    getMap = (id, dates, datef) => {
        return axios.post('/api/map/problems', {
            id,
            dates,
            datef
        });
    }

    getMapProblem = (id) => {
        return axios.post('/api/map/problem/' + id, {});
    }
}

export default new MapServive();



// register = (username, email, password, name, surname, token) => {
//     return axios.post(API_URL + "/signup", {
//         username,
//         email,
//         password,
//         name,
//         surname,
//         token
//     });
// }