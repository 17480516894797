import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react'
import Spinner from "react-bootstrap/Spinner";
import Card from 'react-bootstrap/Card';
import { RiArrowGoBackLine } from "react-icons/ri";
import { AiOutlineEnter } from "react-icons/ai";
import ListGroup from 'react-bootstrap/ListGroup';
import { TbStepInto } from "react-icons/tb";
import { CgAttachment } from "react-icons/cg";
import axios from "axios";
import { MdOutlineDone } from "react-icons/md";
import { FaRegSadTear } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { Alert } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner'
//import { el } from "date-fns/locale";


const Createrequest_confirm_modal = ({ show, onHide: closeModal, onExit, onShow,
    problem, reason, comment, files, gpublic, myAddress, selectedStreet, house, flat, house_p, corpus, rn
}) => {

    let navigate = useNavigate();
    const [spiner, setSpiner] = useState(false);
    const [status, setStatus] = useState(false);
    const [size, setSize] = useState('lg');
    const [error, setError] = useState(false);
    const [showReCAPTCHA, setShowReCAPTCHA] = useState(false)
    const recaptchaRef = React.createRef();
    const [showError, setShowError] = useState(false);
    const [waitcaptha, setWaitcaptha] = useState(false);

    onExit = () => {

        setSpiner(false)
        setError(false)
        if (status) {
            navigate("/profile");
        }

    }


    onShow = () => {
        setSpiner(false)
        //setStatus(true)
        setError(false)

        //check need show captcha
        
        // setTimeout(() => {

        //     setShowReCAPTCHA(false)

        

           
        //     setWaitcaptha(false) 
        // }, 2000);
        checkCaptcha();



    }
    const checkCaptcha = async () => {
        setWaitcaptha(true)
        try {
            const response = await axios.post(

                "/api/user/needcaptcha", {})
           
            if (response.data.captcha === 'yes') {
                setWaitcaptha(false)
                setShowReCAPTCHA(true)
            } else{
                setWaitcaptha(false)
                setShowReCAPTCHA(false)
            }
            
        } catch (error) {
            setWaitcaptha(false)
        }
        

    }


    const handleSubmit = async () => {


 
        if (showReCAPTCHA){
         if (recaptchaRef.current.getValue() === '') {
            setShowError(true)
            return
         }
        }
       

        setError(false)
        setSpiner(true)

        let formData = new FormData();

        if (files.length !== 0) {
            files.forEach(number => {
                if (number.valid) {
                    formData.append("files", number.file);
                }
            });
        }

        formData.append("P_PROBLEM_ID", problem.id);
        formData.append("P_REASON_ID", reason.id);
        
        if (showReCAPTCHA){
            formData.append("token", recaptchaRef.current.getValue());
        } else{
            formData.append("token", '');
        }
       

        if (comment === null) {
            formData.append("P_DESCRIPTION", '');
        } else {
            formData.append("P_DESCRIPTION", comment);
        }

        // formData.append("P_DESCRIPTION", comment);
        formData.append("P_IS_PUBLIC", gpublic);
        formData.append("myAddress", myAddress);

        if (!selectedStreet != null && !myAddress) {
            formData.append("P_STREET_ID", selectedStreet.value);

            if (house_p) {
                formData.append("P_HOUSE_NUM", house + house_p.value);
            } else {
                formData.append("P_HOUSE_NUM", house);
            }

            formData.append("P_FLAT_NUM", flat);
            formData.append("P_CORPUS", corpus);
            formData.append("p_district_id", rn.id);
        }


        try {
            const response = await axios.post(

                "/api/user/upload", formData, {

                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
           
            if (response.data.message === 'ok') {
                 //recaptchaRef.current.reset()
                setSpiner(false)

                setStatus(true)
                setSize('md')

            } else {

                if (response.data.message === 'captcha') {
                    //recaptchaRef.current.reset()
                    setShowReCAPTCHA(true)
                    setShowError(true)
                    setSpiner(false)
   
               } else {

                setSize('md')
                setSpiner(false)
                setError(true)
                setStatus(true) 
            }
            }
        } catch (error) {
            setSize('md')
            setSpiner(false)
            setError(true)
            setStatus(true)
        }


    }


    return (
        <>
            <>
                <Modal show={show} fullscreen='md-down' backdrop="static" size={size} centered
                    onHide={closeModal}
                    onExit={onExit}
                    onShow={onShow}>

                    {!status && <>
                        <Modal.Header>
                            <Modal.Title>Підтвердження створення звернення
                                {gpublic && <div className="confirm-title"><BiWorld size={18} /> Звернення відображається сайті</div>}

                            </Modal.Title>
                        </Modal.Header>
                    </>}
                    <Modal.Body>

                        {/* <Alert className="non-street-user-profile" show={error} variant='danger'>Сталася технічна помилка спробуйте пізніще</Alert> */}

                        {error && <>
                            <Row>
                                <div className="col">
                                    <span className="sad-icon"><FaRegSadTear /></span>
                                </div></Row>

                            <div className="col sad-icon2 py-1">
                                Сталася технічна помилка,
                            </div> <div className="col sad-icon2 py-1">
                                спробуйте подати звернення пізніще
                            </div>


                            <Row className="py-4">
                                <Col className="d-flex justify-content-center">
                                    <Button variant="primary" type="" onClick={closeModal}
                                    >
                                        Закрити форму
                                    </Button>{' '}
                                </Col>
                            </Row>
                        </>}

                        {status && !error && <>
                            <div className="d-flex justify-content-center row py-5">
                                <Row>
                                    <div className="col">
                                        <span className="rememberPaswordResult"><MdOutlineDone /></span>
                                    </div></Row><Row>
                                    <div className="col rememberPaswordResult2 py-2">
                                        Ваше звернення успішно зареєстроване
                                    </div></Row>

                                <Row className="py-2">
                                    <Col className="d-flex justify-content-center">
                                        <Button className="login_modal_button3 py-2" variant="primary" type="" onClick={closeModal}
                                        >
                                            Повернутися в мій 15-80
                                        </Button>{' '}
                                    </Col>
                                </Row>
                            </div>
                        </>}

                        {!status && <>
                            <Card>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>

                                        <div className=" text-center">
                                            <h5 className="mb-0 mx-2 creare-request-title"><b>Проблема</b></h5>
                                            <TbStepInto className='creare-request-title-icon' /></div>

                                        <div className="mb-1 text-center pt-1 pb-1">
                                            <div className="mb-0 mx-2">
                                                {problem &&
                                                    <>{problem.name}</>
                                                }
                                            </div>
                                        </div>


                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <div className=" text-center">
                                            <h5 className="mb-0 mx-2 creare-request-title"><b>Причина</b></h5>
                                            <TbStepInto className='creare-request-title-icon' /></div>

                                        <div className="mb-1 text-center pt-1 pb-1">
                                            <div className="mb-0 mx-2">
                                                {reason &&
                                                    <>{reason.name_ua}</>
                                                }
                                            </div>
                                        </div>

                                    </ListGroup.Item>

                                    {comment &&
                                        <>
                                            <ListGroup.Item>
                                                <div className=" text-center">
                                                    <h5 className="mb-0 mx-2 creare-request-title"><b>Опис проблеми</b></h5>
                                                    <TbStepInto className='creare-request-title-icon' /></div>

                                                <div className="mb-1 text-center pt-1 pb-1">
                                                    <div className="mb-0 mx-2">
                                                        {comment}
                                                    </div>
                                                </div>
                                            </ListGroup.Item>
                                        </>
                                    }

                                    {files.length > 0 && <>
                                        <ListGroup.Item>
                                            <div className="text-center">
                                                <h5 className="mb-0 mx-2 creare-request-title"><b>Файли</b></h5>
                                                <TbStepInto className='creare-request-title-icon' /></div>

                                            <div className="mb-1 text-center pt-1 pb-1">
                                                <div className="mb-0 mx-2">


                                                    {files && files.map(d => (

                                                        d.valid ? <div className=" pe-1 text-truncate"><CgAttachment />{d.file.name}</div> : <></>

                                                    ))}

                                                </div>
                                            </div> </ListGroup.Item></>
                                    }

                                    <ListGroup.Item>
                                        <div className="text-center">
                                            <h5 className="mb-0 mx-2 creare-request-title"><b>Адреса</b></h5>
                                            <TbStepInto className='creare-request-title-icon' /></div>

                                        <div className="mb-1 text-center pt-1 pb-1">
                                            <div className="mb-0 mx-2">
                                                {myAddress && <>Моя адреса</>}
                                                {!myAddress && selectedStreet && <>

                                                    {rn && <>
                                                        <span>{rn.district_name}{' район, '}</span>
                                                    </>}

                                                    <span className="nowrap">{selectedStreet.labeltype}{" "}{selectedStreet.label}{", "}</span>
                                                    <span>{' '}{house}</span>

                                                    {house_p &&
                                                        <>{house_p.label}</>
                                                    }

                                                    {corpus && corpus !== '' &&
                                                        <span>{', Корпус '}{corpus}{', '}</span>
                                                    }
                                                    {flat && flat !== '' &&
                                                        <>{" кв. "}{flat}</>
                                                    }

                                                </>
                                                }
                                            </div>
                                        </div>

                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                            {!waitcaptha && <>
                            {showReCAPTCHA && <>
                                <Col className="d-flex justify-content-center pt-3">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey='6LfELHYoAAAAACYO1ngyjP8MbXoI7mTnriKL5Kup'
                                        onChange={() => (setShowError(false))}

                                    />

                                </Col>
                                <Alert className="text-center loginFormAlert" show={showError} variant='danger'>Натисніть на капчу !</Alert>
                            </>}


                            <Row className="pt-3">





                                <Col className="d-flex justify-content-center">

                                    <Button className="login_modal_button3 py-2" variant="primary" type="" onClick={handleSubmit}
                                        disabled={spiner}>
                                        {spiner ? (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <><AiOutlineEnter /> Подати</>
                                        )}
                                    </Button>{' '}
                                </Col>

                            </Row>

                            <Row className="pt-2">
                                <Col className="d-flex justify-content-center">
                                    <Button className="login_modal_button3 py-2" variant="link" type="" onClick={closeModal} disabled={spiner}
                                    >
                                        <RiArrowGoBackLine />  Коригувати
                                    </Button>{' '}
                                </Col>
                            </Row>
                            </>}

{waitcaptha && <>
    <div className='d-grid d-flex justify-content-center py-5'>
                            <ThreeDots
                                height="160"
                                width="160"
                                radius="10"
                                color="#4fa94d"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                            />
                        </div>
</>}
                            

                        </>}
                    </Modal.Body>

                </Modal>
            </>
        </>
    )
}

export default Createrequest_confirm_modal