import axios from 'axios';
import AuthService from "../services/AuthService";




const AxiosInterceptorsSetup = navigate => {



    const instance = axios.create({
        baseURL: "/api",
        headers: {
            'Content-Type': 'application/json'
        }
    });

    axios.interceptors.response.use(

        response => response,

        async (error) => {


            const originalConfig = error.config;

            if (error.response && !error.config.url.includes('checkUser')) {

                // if (error.response) {

                if (error.response.status === 401 && !originalConfig._retry) {

                    originalConfig._retry = true;

                    try {

                        const rs = await refreshToken();
                        localStorage.setItem("user", JSON.stringify(rs.data))
                        return axios.request(originalConfig)

                    } catch (_error) {

                     //   console.log(_error.response.status)

                        if (_error.response && _error.response.data) {


                            if (_error.response.status === 403) {

                                AuthService.logout();
                                navigate('/');
                                window.location.reload(false);

                            } else {

                                return Promise.reject(_error.response.data);

                            }

                        }

                        return Promise.reject(error);
                    }

                }
            }


            return Promise.reject(error.config);
        }
    );


    function refreshToken() {

        const user = JSON.parse(localStorage.getItem('user'));
        const refreshToken = user.refreshToken

           return instance.post("/auth/refreshtoken", {
              refreshToken
          }); 


       // return AuthService.refreshtoken(refreshToken);

    }

};

export default AxiosInterceptorsSetup;